.privacy__container {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  margin-top: 170px;

  @media screen and (max-width: 1360px) {
    max-width: 560px;
    margin-top: 136px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 80px;
    padding: 0 15px;
  }
}
.privacy__title {
  font-size: 3.5rem;
  letter-spacing: 0.2em;
  color: $color-bk;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 1360px) {
    font-size: 2.8rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 2.2rem;
    letter-spacing: 0.1em;
  }
}
.privacy__text {
  margin-top: 62px;
  font-size: 1.4rem;
  line-height: 2;

  @media screen and (max-width: 1360px) {
    margin-top: 49.6px;
    font-size: 1.12rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 38px;
    font-size: 1.4rem;
    line-height: 1.785714286;
  }
}

// pc-s
// @media screen and (max-width: 1360px) {
//   .privacy__container {
//     max-width: 560px;
//     margin-top: 136px;
//   }
//   .privacy__title {
//     font-size: 2.8rem;
//   }
//   .privacy__text {
//     margin-top: 49.6px;
//     font-size: 1.12rem;
//   }
// }

// // sp
// @media screen and (max-width: 670px) {
//   .privacy__container {
//     margin-top: 80px;
//     padding: 0 15px;
//   }
//   .privacy__title {
//     font-size: 2.2rem;
//     letter-spacing: 0.1em;
//   }
//   .privacy__text {
//     margin-top: 38px;
//     font-size: 1.4rem;
//     line-height: 1.785714286;
//   }
// }
