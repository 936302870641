/* header
------------------------------------------------ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;

  .gnav-sp {
    display: none;

    @media screen and (max-width: 1080px) {
      display: block;
    }
  }
}

.header__inner {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $color-wh;

  @media screen and (max-width: 1080px) {
    height: res(90px);
  }

  @media screen and (max-width: 670px) {
    height: 60px;
  }
}

.header-logo {
  max-width: 162px;
  margin-left: 33px;

  @media screen and (max-width: 1360px) {
    max-width: res(162px);
    margin-left: res(33px);
  }

  @media screen and (max-width: 670px) {
    max-width: 118px;
    margin-left: 17px;
  }
}

/* ---------------------------------------------
  gnav
------------------------------------------------ */
.gnav {
  margin-left: auto;
}

.gnav__list {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1080px) {
    display: none;
  }
}

.gnav__item {
  position: relative;
  display: block;
  padding: 36px 0;
  font-size: 1.7rem;
  letter-spacing: 0.2em;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    padding: res(36px) 0;
    font-size: res(1.7rem);
  }

  &:last-child {
    margin-right: 0;
    margin-left: 74px;

    @media screen and (max-width: 1360px) {
      margin-left: res(74px);
    }
  }

  &.hvBtn {
    padding: 36px 74px;

    @media screen and (max-width: 1360px) {
      padding: res(36px) res(74px);
    }
  }

  & > a {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -8px;
      transition: width 0.3s;
      width: 0;
      height: 1px;
      background-color: $color-bk;
      opacity: 0;
    }

    &:hover {
      opacity: 1;
      transition: width 0.3s;

      &::before {
        width: 100%;
        opacity: 1;
      }
    }
  }

  &.-tel {
    background-color: $color-main;
    padding: 0;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: bold;
      padding: 20px 24px 19px;
      font-size: 1.5rem;
      color: $color-wh;

      @media screen and (max-width: 1360px) {
        padding: res(20px) res(24px) res(19px);
        font-size: res(1.5rem);
      }

      &::before {
        content: none;
      }

      &:hover {
        opacity: 0.7;
      }

      img {
        width: 38px;
        height: 28px;
        margin-bottom: 8px;

        @media screen and (max-width: 1360px) {
          width: res(38px);
          height: res(28px);
          margin-bottom: res(8px);
        }
      }
    }
  }

  .sublist {
    position: fixed;
    top: 90px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #9ea29b;
    padding: 32px 0;
    color: $color-wh;

    // @media screen and (max-width: 1360px) {
    //   padding: 25.6px 0;
    //   transform: translateY(-120px);
    // }
  }

  .sublist__item {
    position: relative;
    max-width: 260px;
    width: 100%;
    margin-left: 25px;

    // @media screen and (max-width: 1360px) {
    //   max-width: 208px;
    //   font-size: 1.28rem;
    // }

    &:first-child {
      margin-left: 0;
    }

    a {
      position: relative;
      display: block;
      padding: 20px 0;
      border: 1px solid #fff;
      border-radius: 5px;
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.2em;
      text-align: center;

      @media screen and (max-width: 1360px) {
        // padding: 16px 0;
      }

      &::before {
        content: "";
        position: absolute;
        width: 17px;
        height: 6px;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        background-image: url(../images/icon/arrowf.svg);
        background-repeat: no-repeat;
        background-size: contain;

        @media screen and (max-width: 1360px) {
          // width: 13.6px;
          // height: 4.8px;
          // right: 16px;
        }
      }

      &:hover {
        background-color: $color-wh;
        color: $color-bk;
        opacity: 1;

        &::before {
          background-image: url(../images/icon/arrow.svg);

          // @media screen and (max-width: 670px) {
          //   width: 15px;
          //   height: 5px;
          //   right: 18px;
          // }
        }
      }
    }
  }
}

// pc-s
// @media screen and (max-width: 1360px) {
//   .header__inner {
//     height: 72px;
//   }
//   .header .header-logo {
//     margin-left: 16px;
//   }
//   .header .header-logo img {
//     width: 88px;
//   }
//   .header__info {
//     margin-right: 14.4px;
//   }
//   .header .gnav__item {
//     margin-right: 59.2px;
//     font-size: 1.36rem;
//   }
//   .header .gnav__item .sublist {
//     padding: 25.6px 0;
//     transform: translateY(-120px);
//   }
//   .header .gnav__item .sublist__item {
//     max-width: 208px;
//     font-size: 1.28rem;
//   }
//   .header .gnav__item .sublist__item a {
//     padding: 16px 0;
//   }
//   .header .gnav__item .sublist__item a::before {
//     width: 13.6px;
//     height: 4.8px;
//     right: 16px;
//   }
//   .header .gnav__item.open .sublist {
//     transform: translateY(72px);
//   }
//   .header .gnav__item:last-child {
//     margin-right: 0;
//   }
//   .header .gnav__item.-tel a {
//     padding: 16px 19.2px 15.2px;
//     font-size: 1.2rem;
//   }
//   .header .gnav__item.-tel img {
//     width: 30.4px;
//     height: 22.4px;
//     margin-bottom: 6.4px;
//   }
// }

// tb
// @media screen and (max-width: 1080px) {
//   .header .gnav-sp {
//     display: block;
//   }
//   .header .gnav__list {
//     display: none;
//   }
//   .header__btn {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 60px;
//     height: 60px;
//   }
// }

// sp
// @media screen and (max-width: 670px) {
//   .header__inner {
//     height: 60px;
//   }
//   .header .header-logo {
//     margin-left: 5px;
//   }
//   .header .header-logo img {
//     width: 72px;
//   }
//   .header__info {
//     margin-right: 0;
//   }
//   .header .gnav__item .sublist__item a::before {
//     width: 15px;
//     height: 5px;
//     right: 18px;
//   }
// }
