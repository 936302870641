// 確認画面用
.mw_wp_form_confirm {
  .form {
    &__note {
      display: none;
    }

    &__list {
      margin-top: 0;
      padding: 34px 0;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      @include media(sm) {
        padding: res(50px) 0;
      }

      @include media(lg) {
        padding: 50px 0;
      }
    }

    &__item {
      margin-top: 32px;

      @include media(sm) {
        margin-top: res(40px);
      }

      @include media(lg) {
        margin-top: 40px;
      }

      &:first-child {
        margin-top: 0;
      }

      .input,
      .textarea {
        font-size: 1.6rem;
        line-height: line(30, 16);

        @include media(md) {
          font-size: res(1.6rem);
        }

        @include media(lg) {
          font-size: 1.6rem;
        }
      }
    }

    &__title {
      & > .red {
        display: none;
      }
    }

    &__box {
      margin-top: 8px;

      @include media(md) {
        margin-top: 0;
      }
    }

    .formPrivacy {
      display: none;
    }
  }

  // submitBtn
  .formBtn {
    margin-top: 40px;

    @include media(md) {
      margin-top: res(70px);
    }

    @include media(lg) {
      margin-top: 70px;
    }

    &__inner {
      flex-direction: row-reverse;
      margin-left: -12px;

      @include media(md) {
        margin-left: res(-20px);
      }

      @include media(lg) {
        margin-left: -20px;
      }
    }

    &__btn {
      width: calc(50% - 12px);
      margin-top: 0;
      margin-left: 12px;

      @include media(sm) {
        width: calc(50% - #{res(20px)});
        margin-left: res(20px);
      }

      @include media(lg) {
        width: calc(50% - 20px);
        margin-left: 20px;
      }

      // 修正するボタン
      &.-backBtn {
        display: block !important;

        .arrow {
          position: absolute;
          top: 49%;
          left: 16px;
          transform: translateY(-50%);
          display: inline-block;
          width: 18px;
          height: 1px;
          background-color: $color-bk;

          @include media(md) {
            left: res(26px);
            width: res(19px);
          }

          @include media(lg) {
            left: 26px;
            width: 19px;
          }

          &::before {
            content: "";
            position: absolute;
            left: 0.24em;
            width: 10px;
            height: 10px;
            border: none;
            border-bottom: 1px solid $color-bk;
            border-left: 1px solid $color-bk;
            transform: translateY(9%) rotate(45deg);
            transform-origin: top right;

            @include media(md) {
              width: res(10px);
              height: res(10px);
            }

            @include media(lg) {
              width: 10px;
              height: 10px;
            }
          }
        }

        .submitBtn {
          background-color: $color-wh;
          padding-right: 0;
          padding-left: 6px;
          border: 1px solid #d5d6d6;
          color: $color-bk;

          @include media(md) {
            padding-left: 0;
          }
        }
      }
    }
  }
}
