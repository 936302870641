/* TOP
------------------------------------------------- */
.top__title {
  font-size: 3.8rem;
  letter-spacing: 0.5em;
  font-family: $font-en;
  text-align: center;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    font-size: 3.04rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 2.8rem;
  }
}

.topInfo__container {
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  margin-top: 115px;
  margin-bottom: 113px;

  @media screen and (max-width: 1360px) {
    max-width: 776px;
    margin-top: 92px;
    margin-bottom: 90.4px;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 77px;
    margin-bottom: 84px;
    padding: 0 30px;
  }

  @media screen and (max-width: 670px) {
    padding: 0 15px;
  }
}
.topInfo__box {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 670px) {
    flex-direction: column;
    max-width: 330px;
    width: 100%;
    margin: 0 auto;
  }
}

.topInfo__img {
  @media screen and (max-width: 670px) {
    margin-right: auto;
  }
}

.topInfo__img img {
  width: 340px;

  @media screen and (max-width: 1360px) {
    width: 272px;
  }

  @media screen and (max-width: 670px) {
    width: 258px;
  }
}
.topInfo__text {
  margin-left: 62px;
  font-size: 1.5rem;
  line-height: 2.266666667;
  letter-spacing: 0.1em;

  @media screen and (max-width: 1360px) {
    margin-left: 49.6px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1080px) {
    margin-left: 30px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 30px;
    margin-left: 0;
    font-size: 1.5rem;
    line-height: 2.133333333;
  }
}

.topService__list {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 32px;

  @media screen and (max-width: 1360px) {
    margin-top: 25.6px;
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;
    margin-top: 18px;
  }
}
.topService__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 400px;
  text-align: center;
  border-left: 1px solid #fff;
  color: $color-wh;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  overflow: hidden;
  transition: opacity 0.5s;

  @media screen and (max-width: 1360px) {
    height: 320px;
  }

  @media screen and (max-width: 670px) {
    width: 100%;
    height: 300px;
    border-left: none;
    border-top: 1px solid #fff;
  }
}
// .topService__item.-item01 {
//   border-left: none;
//   background-image: url(../images/top/img01@2x.jpg);
// }
.topService__item.-item02 {
  background-image: url(../images/top/img02@2x.jpg);
}
.topService__item.-item03 {
  background-image: url(../images/top/img03@2x.jpg);
}
.topService__item:hover {
  transition: opacity 0.5s;
  opacity: 0.7;

  @media screen and (max-width: 670px) {
    opacity: 1;
  }
}
.topService__item:hover::before {
  content: "";
  background: inherit;
  filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='flt-kj2gdmqo' x='0' y='0' width='1' height='1' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='4' result='flt-kj2gdmqo-2'/%3E%3CfeMorphology operator='dilate' radius='4'/%3E%3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='flt-kj2gdmqo-2'/%3E%3C/feMerge%3E%3C/filter%3E%3C/svg%3E#flt-kj2gdmqo");
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
}
.topService__item:hover .card__text {
  transition: 0.2s;
  width: 95px;

  @media screen and (max-width: 1360px) {
    width: 76px;
  }

  @media screen and (max-width: 670px) {
  }
}
.topService__item a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}
.topService__item .card {
  position: relative;
  z-index: 1;
}
.topService__item .card__title {
  margin-top: 20px;
  font-size: 2.7rem;
  letter-spacing: 0.1em;
  font-weight: bold;

  @media screen and (max-width: 1360px) {
    margin-top: 16px;
    font-size: 2.16rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 24px;
    font-size: 2.3rem;
    font-weight: bold;
  }
}
.topService__item .card__text {
  width: 90px;
  margin: 0 auto;
  margin-top: 32px;
  padding-bottom: 8px;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  border-bottom: 1px solid #fff;
  transition: 0.2s;

  @media screen and (max-width: 1360px) {
    width: 72px;
    margin-top: 25.6px;
    padding-bottom: 6.4px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 30px;
    padding-bottom: 8px;
    font-size: 1.3rem;
  }
}
.topService__item .card__img img {
  max-width: 160px;
  width: 100%;

  @media screen and (max-width: 1360px) {
    max-width: 128px;
  }

  @media screen and (max-width: 670px) {
    max-width: 108px;
  }
}

.topSns {
  margin-top: 108px;

  @media screen and (max-width: 1360px) {
    margin-top: 86.4px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 76px;
  }
}
.topSns__contaicer {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1360px) {
    max-width: 960px;
  }

  @media screen and (max-width: 1080px) {
    max-width: 700px;
    padding: 0 15px;
  }
}
.topSns__list {
  margin-top: 30px;

  @media screen and (max-width: 1360px) {
    margin-top: res(30px);
  }

  @media screen and (max-width: 670px) {
    margin-top: 14px;
  }
}

.topSns__item {
  position: relative;
  width: 232px;
  margin-top: 32px;
  margin-left: 10px;

  @media screen and (max-width: 1360px) {
    width: 185.6px;
    margin-top: 25.6px;
    margin-left: 8px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 20px;
    width: calc(50% - 3px);
    margin-left: 0px;
  }
}
.topSns__item:first-child {
  margin-left: 0;
}
.topSns__item > a {
  position: relative;
  display: block;
  padding-top: 100%;
  overflow: hidden;
}
.topSns__item > a:hover > img,
.topSns__item > a:hover > video {
  transform: translate(-50%, -50%) scale(1.1);
  transform-origin: 50% 50%;
}
.topSns__item > a > img,
.topSns__item > a > video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.topSns__item:nth-child(5) {
  @media screen and (max-width: 670px) {
    display: none;
  }
}
.topSns__item:nth-child(even) {
  @media screen and (max-width: 670px) {
    margin-left: 6px;
  }
}
.topSns__item:nth-child(n + 3) {
  @media screen and (max-width: 670px) {
    margin-top: 6px;
  }
}

.topButton {
  position: relative;
  max-width: 260px;
  width: 100%;
  margin: 0 auto;
  margin-top: 55px;
  text-align: center;

  @media screen and (max-width: 1360px) {
    max-width: 208px;
    margin-top: 44px;
  }

  @media screen and (max-width: 670px) {
    max-width: 250px;
    margin-top: 16px;
  }
}
.topButton.-mt {
  margin-top: 36px;

  @media screen and (max-width: 1360px) {
    margin-top: 28.8px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 24px;
  }
}
.topButton:hover a {
  background-color: $color-main;
  color: $color-wh;
  transition: 0.3s;
}
.topButton:hover a::before {
  background-image: url(../images/icon/arrowf.svg);
}
.topButton a {
  position: relative;
  display: block;
  padding: 18px 0;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.2em;
  color: $color-main;
  border: 1px solid $color-main;
  border-radius: 5em;
  transition: 0.3s;

  @media screen and (max-width: 1360px) {
    padding: 14.4px 0;
    font-size: 1.44rem;
  }

  @media screen and (max-width: 670px) {
    padding: 16px 0;
    font-size: 1.8rem;
  }
}

.topButton a::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 6px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-image: url(../images/icon/arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;

  @media screen and (max-width: 1360px) {
    width: 13.6px;
    height: 4.8px;
    right: 16px;
  }

  @media screen and (max-width: 670px) {
    width: 15px;
    height: 5px;
    right: 18px;
  }
}

// pc-s
// @media screen and (max-width: 1360px) {
//   .top__title {
//     font-size: 3.04rem;
//   }
//   .topInfo__container {
//     max-width: 776px;
//     margin-top: 92px;
//     margin-bottom: 90.4px;
//   }
//   .topInfo__img img {
//     width: 272px;
//   }
//   .topInfo__text {
//     margin-left: 49.6px;
//     font-size: 1.2rem;
//   }
//   .topService__list {
//     margin-top: 25.6px;
//   }
//   .topService__item {
//     height: 320px;
//   }
//   .topService__item:hover .card__text {
//     width: 76px;
//   }
//   .topService__item .card__title {
//     margin-top: 16px;
//     font-size: 2.16rem;
//   }
//   .topService__item .card__text {
//     width: 72px;
//     margin-top: 25.6px;
//     padding-bottom: 6.4px;
//     font-size: 1.2rem;
//   }
//   .topService__item .card__img img {
//     max-width: 128px;
//   }
//   .topSns {
//     margin-top: 86.4px;
//   }
//   .topSns__contaicer {
//     max-width: 960px;
//   }
//   .topSns__item {
//     width: 185.6px;
//     margin-top: 25.6px;
//     margin-left: 8px;
//   }
//   .topButton {
//     max-width: 208px;
//     margin-top: 44px;
//   }
//   .topButton.-mt {
//     margin-top: 28.8px;
//   }
//   .topButton a {
//     padding: 14.4px 0;
//     font-size: 1.44rem;
//   }
//   .topButton a::before {
//     width: 13.6px;
//     height: 4.8px;
//     right: 16px;
//   }
// }

// tb {
// @media screen and (max-width: 1080px) {
//   .topInfo__container {
//     margin-top: 77px;
//     margin-bottom: 84px;
//     padding: 0 15px;
//   }
//   .topSns__contaicer {
//     max-width: 700px;
//     padding: 0 15px;
//   }
// }

// sp
// @media screen and (max-width: 670px) {
//   .top__title {
//     font-size: 2.8rem;
//   }
//   .topInfo__box {
//     flex-direction: column;
//     max-width: 330px;
//     width: 100%;
//     margin: 0 auto;
//   }
//   .topInfo__img {
//     margin-right: auto;
//   }
//   .topInfo__img img {
//     width: 258px;
//   }
//   .topInfo__text {
//     margin-top: 30px;
//     margin-left: 0;
//     font-size: 1.5rem;
//     line-height: 2.133333333;
//   }
//   .topService__list {
//     flex-direction: column;
//     margin-top: 18px;
//   }
//   .topService__item {
//     width: 100%;
//     height: 300px;
//     border-left: none;
//     border-top: 1px solid #fff;
//   }
//   .topService__item:hover {
//     opacity: 1;
//   }
//   .topService__item .card__title {
//     margin-top: 24px;
//     font-size: 2.3rem;
//     font-weight: bold;
//   }
//   .topService__item .card__text {
//     margin-top: 30px;
//     padding-bottom: 8px;
//     font-size: 1.3rem;
//   }
//   .topService__item .card__img img {
//     max-width: 108px;
//   }
//   .topSns {
//     margin-top: 76px;
//   }
//   .topSns__list {
//     flex-wrap: wrap;
//     justify-content: flex-start;
//     width: 100%;
//   }
//   .topSns__item {
//     margin-top: 20px;
//     width: calc(50% - 3px);
//     margin-left: 0px;
//   }
//   .topSns__item:nth-child(5) {
//     display: none;
//   }
//   .topSns__item:nth-child(even) {
//     margin-left: 6px;
//   }
//   .topSns__item:nth-child(n + 3) {
//     margin-top: 6px;
//   }
//   .topButton {
//     max-width: 250px;
//     margin-top: 16px;
//   }
//   .topButton.-mt {
//     margin-top: 24px;
//   }
//   .topButton a {
//     padding: 16px 0;
//     font-size: 1.8rem;
//   }
//   .topButton a::before {
//     width: 15px;
//     height: 5px;
//     right: 18px;
//   }
// }
