/* メインの設定(全体)
------------------------------- */

// common
.anker {
  display: block;
  margin-top: -50px;
  padding-top: 50px;

  @media screen and (max-width: 1360px) {
    margin-top: -40px;
    padding-top: 40px;
  }

  @media screen and (max-width: 1080px) {
    margin-top: -72px;
    padding-top: 72px;
  }

  @media screen and (max-width: 670px) {
    margin-top: -60px;
    padding-top: 60px;
  }
}

// inner
.inner {
  position: relative;
  max-width: 960px;
  margin: 0 auto;

  @media screen and (max-width: 1360px) {
    max-width: 768px;
  }

  @media screen and (max-width: 1080px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 670px) {
    padding: 0 15px;
  }
}

// main
.main {
  position: relative;
  display: block;
}

//asp
.--asp {
  position: relative;
  width: 100%;

  &::before {
    content: "";
    display: block;
  }

  img,
  iframe,
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// br関連
.d-tb,
.d-sp {
  display: none;
}

.d-pc {
  @media screen and (max-width: 1360px) {
    display: block;
  }

  @media screen and (max-width: 670px) {
    display: none;
  }
}

.d-tb {
  @media screen and (max-width: 1360px) {
    display: none;
  }

  @media screen and (max-width: 1080px) {
    display: block;
  }
}

.d-sp {
  @media screen and (max-width: 1360px) {
    display: none;
  }

  @media screen and (max-width: 670px) {
    display: block;
  }
}
