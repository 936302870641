.contact__text {
  margin-top: 84px;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  line-height: 2.133333333;
  text-align: center;

  @media screen and (max-width: 1360px) {
    margin-top: 67.2px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 56px;
    font-size: 1.5rem;
    line-height: 1.866666667;
  }
}

.contactConfirm__text {
  font-size: 2.3rem;
  line-height: 1.739130435;
  letter-spacing: 0.1em;
  font-weight: bold;

  @media screen and (max-width: 1360px) {
    font-size: 1.84rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 2.1em;
    line-height: 1.619047619;
  }

  &>span{
    font-size: 1.5rem;
    font-weight: normal;

    @media screen and (max-width: 1360px) {
      font-size: 1.2rem;
    }

    @media screen and (max-width: 670px) {
      font-size: 1.5rem;
    }
  }
}

// 確認画面
.contactConfirm {
  margin-top: 88px;
}
.contactConfirm__text {
  text-align: center;
  font-size: 2.3rem;
  letter-spacing: 0.1em;
  font-weight: bold;

  @media screen and (max-width: 1360px) {
    font-size: 1.84rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 2.1em;
    line-height: 1.619047619;
  }

  &>span{
    display: block;
    margin-top: 8px;
    font-size: 1.5rem;
    font-weight: normal;

    @media screen and (max-width: 1360px) {
      font-size: 1.2rem;
    }

    @media screen and (max-width: 670px) {
      font-size: 1.5rem;
    }
  }
}


// @media screen and (max-width: 1360px) {
//   .contact__text {
//     margin-top: 67.2px;
//     font-size: 1.2rem;
//   }
//   .contact .contactConfirm__text {
//     font-size: 1.84rem;
//   }
//   .contact .contactConfirm__text span {
//     font-size: 1.2rem;
//   }
// }

// @media screen and (max-width: 670px) {
//   .contact__text {
//     margin-top: 56px;
//     font-size: 1.5rem;
//     line-height: 1.866666667;
//   }
//   .contact .contactConfirm__text {
//     font-size: 2.1em;
//     line-height: 1.619047619;
//   }
//   .contact .contactConfirm__text span {
//     font-size: 1.5rem;
//   }
// }
