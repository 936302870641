//font-family
$font: "YuGothic", "Yu Gothic", "Hiragino Sans", "游ゴシック体", sans-serif;
$font-en: "Barlow Condensed", sans-serif;

//font-size
$font-size: 10px;

// カラー
$color-bk: #2c2c30;
$color-wh: #fff;
$color-main: #67ADAE;
