// pc
.service {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1360px) {
    max-width: 768px;
  }

  @media screen and (max-width: 1080px) {
    padding: 0 10px;
  }

  @media screen and (max-width: 670px) {
    padding: 0 15px;
  }
}
.service__text {
  margin-top: 90px;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  text-align: center;

  @media screen and (max-width: 1360px) {
    margin-top: 72px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 44px;
    font-size: 1.5rem;
    line-height: 1.866666667;
  }
}

.service__img {
  margin-top: 50px;
  text-align: center;

  @media screen and (max-width: 1360px) {
    margin-top: 40px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 24px;
  }

  & img{
    max-width: 830px;
    width: 100%;

    @media screen and (max-width: 1360px) {
      max-width: 664px;
    }

    @media screen and (max-width: 670px) {
      max-width: 300px;
    }
  }
}

.service__title {
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
  padding: 104px 0 92px;
  font-size: 3.5rem;
  letter-spacing: 0.2em;
  font-weight: bold;
  text-align: center;
  color: $color-wh;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 1360px) {
    padding: 83.2px 0 73.6px;
    font-size: 2.8rem;
  }

  @media screen and (max-width: 670px) {
    padding: 25px 0 23px;
    font-size: 2.4rem;
  }
}

.service__title.-bg01 {
  margin-top: 88px;
  background-image: url(../images/service/bg01@2x.png);

  @media screen and (max-width: 1360px) {
    margin-top: 70.4px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 58px;
  }
}

.service__title.-bg02 {
  margin-top: 118px;
  background-image: url(../images/service/bg02@2x.png);

  @media screen and (max-width: 1360px) {
    margin-top: 94.4px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 80px;
  }
}
.service__title.-bg03 {
  margin-top: 116px;
  background-image: url(../images/service/bg03@2x.png);

  @media screen and (max-width: 1360px) {
    margin-top: 92.8px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 56px;
  }
}

.service__subtitle {
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 670px) {
    flex-direction: column;
  }
}

.service__subtitle.-ml {
  margin-left: 120px;

  @media screen and (max-width: 1360px) {
    margin-left: 96px;
  }

  @media screen and (max-width: 670px) {
    margin-left: 0;
  }
}
.service__subtitle__img {
  margin-top: 40px;

  @media screen and (max-width: 1360px) {
    margin-top: 32px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 14px;
  }

  & img{
    max-width: 200px;
    width: 100%;

    @media screen and (max-width: 1360px) {
      max-width: 160px;
    }

    @media screen and (max-width: 670px) {
      max-width: 110px;
    }
  }
}

.service__subtitle__text {
  margin-top: 30px;
  margin-left: 34px;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.8;
  text-align: center;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    margin-top: 24px;
    margin-left: 27.2px;
    font-size: 2.4rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 8px;
    margin-left: 0;
    font-size: 2.2rem;
  }
}

.service__subtitle__text.-ml {
  margin-left: 12px;

  @media screen and (max-width: 1360px) {
    margin-left: 9.6px;
  }

  @media screen and (max-width: 670px) {
    margin-left: 0;
  }
}

.consulting {
  text-align: center;
}

.consulting__title {
  margin-top: 40px;
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    margin-top: 32px;
    font-size: 1.92rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 50px;
    font-size: 1.9rem;
    line-height: 1.578947368;
  }
}

.consulting__title.-mt {
  margin-top: 91px;

  @media screen and (max-width: 1360px) {
    margin-top: 72.8px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 54px;
  }
}

.consulting__text {
  margin-top: 24px;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  line-height: 2.133333333;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    margin-top: 19.2px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 14px;
    font-size: 1.5rem;
  }
}

.consulting__list {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  @media screen and (max-width: 1360px) {
    margin-top: 40px;
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;
    margin-top: 28px;
  }
}

.consulting__item {
  margin-left: 46px;

  @media screen and (max-width: 1360px) {
    margin-left: 36.8px;
  }

  @media screen and (max-width: 670px) {
    margin-left: 0;
    margin-top: 22px;
  }

  &:first-child {
    margin-left: 0;

    @media screen and (max-width: 670px) {
      margin-top: 0;
    }
  }
}

.consulting__item .card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.consulting__item .card__img {
  width: 200px;

  &img {
    width: 200px;

    @media screen and (max-width: 1360px) {
      width: 160px;
    }

    @media screen and (max-width: 670px) {
      width: 198px;
    }
  }
}

.consulting__item .card__text {
  width: 160px;
  margin-top: 14px;
  padding: 8px 0 8px;
  background-color: #f4f4f3;
  color: #7f8080;
  font-size: 1.7em;
  font-weight: bold;
  letter-spacing: 0.1em;
  border-radius: 6em;

  @media screen and (max-width: 1360px) {
    width: 128px;
    margin-top: 11.2px;
    padding: 6.4px 0 6.4px;
    font-size: 1.36em;
  }

  @media screen and (max-width: 670px) {
    margin-top: 16px;
    padding: 6px 0 8px;
    font-size: 1.6em;
    letter-spacing: 0.05em;
  }
}

.consulting__img {
  margin-top: 18px;

  @media screen and (max-width: 1360px) {
    margin-top: 14.4px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 30px;
  }

  &img {
    max-width: 810px;
    width: 100%;

    @media screen and (max-width: 1360px) {
      max-width: 648px;
    }

    @media screen and (max-width: 670px) {}
  }
}

.consultingBox {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
  padding: 35px 34px 31px;
  background-color: #f4f4f3;
  border-radius: 10px;

  @media screen and (max-width: 1360px) {
    max-width: 768px;
    margin-top: 48px;
    padding: 28px 27.2px 24.8px;
  }

  @media screen and (max-width: 670px) {
    padding: 28px 32px 28px;
  }
}

.consultingBox__title {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 1.9rem;
    line-height: 1.578947368;
  }
}

.consultingBox__list {
  display: flex;
  margin-top: 28px;

  @media screen and (max-width: 1360px) {
    margin-top: 22.4px;
  }

  @media screen and (max-width: 1080px) {
    justify-content: center;
  }

  @media screen and (max-width: 670px) {
    flex-wrap: wrap;
    max-width: 260px;
    width: 100%;
    margin: 0 auto;
    margin-top: 18px;
  }
}

.consultingBox__item {
  margin-left: 20px;

  @media screen and (max-width: 1360px) {
    margin-left: 16px;
  }

  @media screen and (max-width: 670px) {
    margin-left: 18px;

    &:nth-child(odd) {
      margin-left: 0;
    }

    &:nth-child(n + 3) {
      margin-top: 16px;
    }
  }

  &:first-child {
    margin-left: 0;
  }
}

.consultingBox__item .card__title {
  width: 110px;
  padding: 8px 0;
  font-weight: bold;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  color: #7f8080;
  border: 1px solid #b2b2b2;
  background-color: $color-wh;

  @media screen and (max-width: 1360px) {
    width: 88px;
    padding: 6.4px 0;
    font-size: 1.12rem;
  }

  @media screen and (max-width: 1080px) {
    width: 75px;
  }

  @media screen and (max-width: 670px) {
    width: 118px !important;
    padding: 9px 0;
    font-size: 1.4rem;
  }
}

.consultingBox__item .card__text {
  margin-top: 12px;
  font-size: 1.3rem;
  line-height: 1.461538462;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    margin-top: 9.6px;
    font-size: 1.04rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 10px;
    font-size: 1.3rem;
  }
}

.consultingCost {
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
  margin-top: 84px;
  padding: 32px 0;
  background-color: #4d4d4d;
  color: $color-wh;
  border-radius: 15px;

  @media screen and (max-width: 1360px) {
    max-width: 456px;
    margin-top: 67.2px;
    padding: 25.6px 0;
  }

  @media screen and (max-width: 670px) {
    margin-top: 58px;
    padding: 30px 0;
  }
}

.consultingCost__title {
  font-size: 2rem;
  letter-spacing: 0.1em;
  font-weight: bold;

  @media screen and (max-width: 1360px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 2.2rem;
  }
}

.consultingCost__text {
  margin-top: 10px;
  font-size: 1.5rem;
  letter-spacing: 0.1em;

  @media screen and (max-width: 1360px) {
    margin-top: 8px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1.866666667;
  }
}

.teacch__img {
  max-width: 840px;
  width: 100%;
  margin: 0 auto;
  margin-top: 32px;

  @media screen and (max-width: 1360px) {
    max-width: 672px;
    margin-top: 25.6px;
  }

  @media screen and (max-width: 670px) {
    max-width: 330px;
    margin-top: 20px;
  }
}

.teacch__img__cap {
  margin-top: 10px;
  text-align: right;
  font-size: 1.2rem;

  @media screen and (max-width: 1360px) {
    margin-top: 8px;
    font-size: 0.96rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 4px;
    font-size: 1rem;
  }
}

.teacch__flex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 34px;

  @media screen and (max-width: 1360px) {
    margin-top: 27.2px;
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
  }
}

.teacch__title {
  width: 188px;
  padding: 10px 0 12px;
  font-size: 1.6rem;
  color: $color-bk;
  text-align: center;
  letter-spacing: 0.1em;
  border-left: 1px solid #2c2c30;
  border-right: 1px solid #2c2c30;

  @media screen and (max-width: 1360px) {
    width: 150.4px;
    padding: 8px 0 9.6px;
    font-size: 1.28rem;
  }

  @media screen and (max-width: 670px) {
    width: 180px;
    padding: 6px 0;
    font-size: 1.6rem;
  }
}

.teacch__text {
  margin-left: 30px;
  font-size: 1.3rem;
  letter-spacing: 0.1em;
  line-height: 1.538461538;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    margin-left: 24px;
    font-size: 1.04rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 10px;
    font-size: 1.4rem;
    line-height: 1.785714286;
    text-align: center;
    margin-left: 0;
  }
}

.teacchService__title {
  position: relative;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 16px 0 18px;
  margin-top: 92px;
  font-size: 2.4rem;
  border-radius: 5em;
  background-color: $color-wh;
  letter-spacing: 0.1em;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  z-index: 1;

  @media screen and (max-width: 1360px) {
    max-width: 560px;
    padding: 12.8px 0 14.4px;
    margin-top: 73.6px;
    font-size: 1.92rem;
  }

  @media screen and (max-width: 670px) {
    max-width: 300px;
    margin-top: 52px;
    padding: 16px 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    font-size: 1.7rem;
    line-height: 1.526315789;
  }
}

.teacchService__text {
  margin-top: 36px;
  font-size: 2rem;
  letter-spacing: 0.1em;
  text-align: center;

  @media screen and (max-width: 1360px) {
    margin-top: 28.8px;
    font-size: 1.6rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 34px;
    font-size: 1.8rem;
    line-height: 1.555555556;
  }

  & span {
    font-weight: bold;
  }
}

.teacchServiceBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 960px;
  width: 100%;
  margin-top: -30px;

  @media screen and (max-width: 1360px) {
    max-width: 768px;
    margin-top: -24px;
  }

  @media screen and (max-width: 670px) {
    max-width: 440px;
    margin: 0 auto;
    margin-top: -30px;
    flex-direction: column;
  }
}

.teacchServiceBox__item {
  width: 50%;
  padding: 50px;
  padding-top: 66px;
  background-color: #f3f3f3;

  @media screen and (max-width: 1360px) {
    padding: 40px;
    padding-top: 52.8px;
  }

  @media screen and (max-width: 670px) {
    width: 100%;
    padding: 25px;
    padding-top: 55px;
    padding-bottom: 32px;
  }

  &:last-child {
    background-color: #ebebea;

    @media screen and (max-width: 670px) {
      padding-top: 34px;
    }
  }
}

.teacchServiceBox__item .card {
  color: $color-bk;
}
.teacchServiceBox__item .card__title {
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 15px;
  font-size: 2.4rem;
  letter-spacing: 0.1em;
  line-height: line(30,24);
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #7f8080;

  @media screen and (max-width: 1360px) {
    max-width: 304px;
    padding-bottom: 10.4px;
    font-size: 1.92rem;
  }

  @media screen and (max-width: 670px) {
    width: 100%;
    font-size: 2.1rem;
    text-align: center;
    padding-bottom: 15px;
  }
}

.teacchServiceBox__item .card__title.-bg {
  position: relative;
  margin-left: 5px;
  font-size: 2.2rem;
  text-align: left;
  letter-spacing: 0.1em;

  @media screen and (max-width: 1360px) {
    margin-left: 4px;
    font-size: 1.76rem;
  }

  @media screen and (max-width: 670px) {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-right: 118px;
    font-size: 1.8rem;
  }

  &::before {
    content: "";
    position: absolute;
    width: 71px;
    height: 35px;
    top: -7px;
    right: 3px;
    background-image: url(../images/service/img09@2x.png);
    background-size: contain;
    background-repeat: no-repeat;

    @media screen and (max-width: 1360px) {
      width: 56.8px;
      height: 28px;
      top: -5.6px;
      right: 2.4px;
    }

    @media screen and (max-width: 670px) {
      width: 71px;
      height: 35px;
      top: 50%;
      right: 20px;
      transform: translateY(-60%);
    }
  }

  & span {
    font-size: 1.5rem;

    @media screen and (max-width: 1360px) {
      font-size: 1.2rem;
    }

    @media screen and (max-width: 670px) {
      font-size: 1.5rem;
      line-height: 1.4;
    }
  }
}

.teacchServiceBox__item .card__text {
  margin-top: 22px;
  font-size: 1.4rem;
  line-height: 1.785714286;

  @media screen and (max-width: 1360px) {
    margin-top: 17.6px;
    font-size: 1.12rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 12px;
    font-size: 1.4rem;
    line-height: 1.785714286;
  }

  & .strong{
    font-weight: 700;
  }
}

.teacchServiceBox__item .card__text .narrow{
  letter-spacing: track(-50);
}


// .teacchServiceBox__item .card .cardFlex {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 20px;

//   @media screen and (max-width: 1360px) {
//     margin-top: 17.6px;
//   }

//   @media screen and (max-width: 670px) {
//     flex-direction: column;
//     margin-top: 12px;
//   }
// }

.teacchServiceBox__item .card .cardFlex__text {
  font-size: 1.5rem;
  line-height: 1.8;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-align: center;

  @media screen and (max-width: 1360px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.teacchServiceBox__item .card .cardFlex__img {
  margin-top: 25px;

  @media screen and (max-width: 1360px) {
    margin-top: 20px;
  }
}

.cardTxtArea{
  margin-top: 20px;

  @media screen and (max-width: 1360px) {
    margin-top: res(20px);
  }

  @media screen and (max-width: 670px) {
    margin-top: 15px;
  }
}

.cardTxtArea__text{
  font-size: 1.2rem;
  line-height: line(23,12);

  @media screen and (max-width: 1360px) {
    font-size: res(1.2rem);
  }

  @media screen and (max-width: 670px) {
    font-size: 1.2rem;
  }

  & .strong,
  & .blue{
    font-size: 1.4rem;
    font-weight: 700;

    @media screen and (max-width: 1360px) {
      font-size: res(1.4rem);
    }

    @media screen and (max-width: 670px) {
      font-size: 1.4rem;
    }
  }

  & .blue{
    color: $color-main;
  }
}

.service .teacch .instagrem {
  position: relative;
  margin: 0 auto;
  margin-top: 25px;
  max-width: 460px;
  width: 100%;
  transition: opacity 0.3s;

  @media screen and (max-width: 1360px) {
    max-width: 368px;
    margin-top: res(25px);
  }

  @media screen and (max-width: 670px) {
    max-width: 330px;
    margin-top: 15px;
  }

  &:hover {
    opacity: 0.6;
    transition: opacity 0.3s;
  }

  & a{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.service .event__flex {
  display: flex;
  align-items: center;
  max-width: 966px;
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;

  @media screen and (max-width: 1360px) {
    max-width: 772.8px;
    margin-top: 32px;
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;
  }
}

.service .event__img {
  & img{
    width: 410px;

    @media screen and (max-width: 1360px) {
      width: 328px;
    }
  }
}
.service .event__textbox {
  color: $color-bk;
  text-align: center;
  margin-left: 32px;

  @media screen and (max-width: 1360px) {
    margin-left: 25.6px;
  }

  @media screen and (max-width: 670px) {
    margin-left: 0;
  }
}

.service .event__title {
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: 0.1em;

  @media screen and (max-width: 1360px) {
    font-size: 1.76rem;
  }

  @media screen and (max-width: 1080px) {
    line-height: 1.578947368;
    }

  @media screen and (max-width: 670px) {
    margin-top: 24px;
    font-size: 1.9rem;
  }
}

.service .event__text {
  margin-top: 32px;
  font-size: 1.5rem;
  line-height: 2.133333333;
  letter-spacing: 0.1em;

  @media screen and (max-width: 1360px) {
    margin-top: 25.6px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1.866666667;
  }
}
