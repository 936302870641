.l-sec {
  position: relative;
}
.l-sec__title {
  margin: 0 calc(50% - 50vw);
  background-image: url(../images/external/bg@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  padding: 150px 0 108px;
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: 0.3em;
  text-align: center;
  color: $color-wh;

  @media screen and (max-width: 1360px) {
    padding: 120px 0 86.4px;
    font-size: 3.2rem;
  }

  @media screen and (max-width: 670px) {
    padding: 88px 0 60px;
    font-size: 2.3rem;
  }
}

.l-sec__title span {
  position: relative;
  display: block;
  margin-top: 18px;
  padding-bottom: 12px;
  font-size: 1rem;
  line-height: 0;

  @media screen and (max-width: 1360px) {
    margin-top: 14.4px;
    padding-bottom: 9.6px;
  }

  @media screen and (max-width: 670px) {
    padding-bottom: 8px;
  }
}

.l-sec__title span img {
  width: 160px;

  @media screen and (max-width: 1360px) {
    width: 128px;
  }

  @media screen and (max-width: 670px) {
    width: 133px;
  }
}

.l-sec__title span::before {
  content: "";
  position: absolute;
  background-color: #999899;
  width: 78px;
  height: 1px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 1360px) {
    width: 62.4px;
  }

  @media screen and (max-width: 670px) {
    width: 69px;
  }
}

.sec {
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
  padding-top: 90px;
  padding-bottom: 100px;

  @media screen and (max-width: 1360px) {
    padding-top: 72px;
    padding-bottom: 80px;
  }

  @media screen and (max-width: 670px) {
    padding-top: 46px;
    padding-bottom: 50px;
  }
}
.sec__title {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
}
.sec__title::before {
  content: "";
  display: block;
  padding-top: 8.4905660377%;
}
.sec__title > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.title {
  margin-top: 165px;
  font-size: 6.1rem;
  letter-spacing: 0.5em;
  font-family: $font-en;
  color: #191919;
  text-align: center;

  @media screen and (max-width: 1360px) {
    margin-top: 132px;
    font-size: 4.88rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 79px;
    font-size: 3.7rem;
    line-height: 1.297297297;
  }
}

.title span {
  display: block;
  margin-top: 29px;
  font-size: 2.4rem;
  font-family: $font;
  color: #7f8080;
  font-weight: bold;
  letter-spacing: 0.2em;

  @media screen and (max-width: 1360px) {
    margin-top: 23.2px;
    font-size: 1.92rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 10px;
    font-size: 1.7rem;
  }
}

// pc-s
// @media screen and (max-width: 1360px) {
//   .l-sec__title {
//     padding: 120px 0 86.4px;
//     font-size: 3.2rem;
//   }
//   .l-sec__title span {
//     margin-top: 14.4px;
//     padding-bottom: 9.6px;
//   }
//   .l-sec__title span img {
//     width: 128px;
//   }
//   .l-sec__title span::before {
//     width: 62.4px;
//   }

//   .sec {
//     padding-top: 72px;
//     padding-bottom: 80px;
//   }
//   .title {
//     margin-top: 132px;
//     font-size: 4.88rem;
//   }
//   .title span {
//     margin-top: 23.2px;
//     font-size: 1.92rem;
//   }
// }

// tb

// sp
// @media screen and (max-width: 670px) {
//   .l-sec__title {
//     padding: 88px 0 60px;
//     font-size: 2.3rem;
//   }
//   .l-sec__title span {
//     padding-bottom: 8px;
//   }
//   .l-sec__title span img {
//     width: 133px;
//   }
//   .l-sec__title span::before {
//     width: 69px;
//   }

//   .sec {
//     padding-top: 46px;
//     padding-bottom: 50px;
//   }
//   .title {
//     margin-top: 79px;
//     font-size: 3.7rem;
//     line-height: 1.297297297;
//   }
//   .title span {
//     margin-top: 10px;
//     font-size: 1.7rem;
//   }
// }
