.kv {
  position: relative;
  width: 100%;
  margin-top: 88px;

  @media screen and (max-width: 1360px) {
    margin-top: 70.4px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 60px;
  }
}

.kv .slide-img img {
  width: 100%;
}

.kv .swiper-container,
.kv .swiper-slide {
  @media screen and (max-width: 670px) {
    width: 100%;
    height: auto;
  }
}

.kv .swiper-slide > img {
  @media screen and (max-width: 670px) {
    -o-object-fit: contain;
    object-fit: contain;
  }
}

