.contact .contactForm {
  margin-top: 50px;
  padding: 44px 0;
  border-top: 1px solid #7f8080;
  border-bottom: 1px solid #7f8080;
}
.contact .contactForm__inner {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}
.contact .contactForm__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
}
.contact .contactForm__flex:first-child {
  margin-top: 0;
}
.contact .contactForm__flex.-top {
  align-items: flex-start;
}
.contact .contactForm__text {
  width: 210px !important;
  font-size: 1.7rem;
  line-height: 1.647058824;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #7f8080;
}
.contact .contactForm__text span {
  font-size: 1.3rem;
  color: $color-bk;
  font-weight: normal;
}
.contact .contactForm__check {
  max-width: 468px;
  width: 100%;
  font-size: 1.7rem;
  letter-spacing: 0.1em;
  color: $color-bk;
}
.contact .contactForm__check input {
  border: 1px solid #cccccc;
}
.contact .contactForm__input {
  max-width: 468px;
  width: 100%;
  line-height: 1.3;
}
.contact .contactForm__input input,
.contact .contactForm__input textarea {
  max-width: 468px;
  width: 100%;
  font-size: 1.7rem;
  height: 36px;
  border: 1px solid #cccccc;
  padding: 6px;
}
.contact .contactForm__input textarea {
  height: 302px;
  line-height: 1.4;
}
.contact .contactForm__button {
  position: relative;
  width: 300px;
  margin: 0 auto;
  transition: opacity 0.4s;
}
.contact .contactForm__button::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 6px;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  background-image: url(../images/icon/arrowf.svg);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 6;
}
.contact .contactForm__button:hover {
  opacity: 0.7;
  transition: opacity 0.4s;
}
.contact .contactButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 20px 0;
  color: $color-wh;
  background-color: $color-main;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-size: 1.9rem;
}
.contact .contactButton:hover {
  opacity: 0.8;
}
.contact .contactButton::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 6px;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  background-image: url(../images/icon/arrowf.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.contact .contactButton.-wh {
  margin-top: 15px;
  background-color: $color-wh;
  border: 1px solid $color-main;
  color: $color-main;
}
.contact .contactButton.-wh::before {
  left: 17px;
  transform: translateY(-50%) scale(-1, 1);
  background-image: url(../images/icon/arrow.svg);
}

.mw_wp_form .vertical-item + .vertical-item {
  margin-top: 10px !important;
}

.mw_wp_form.mw_wp_form_input .formnone {
  display: none;
}

.mw_wp_form_preview .contactForm {
  color: $color-bk;
}
.mw_wp_form_preview .contactForm__check {
  font-size: 1.6rem;
  line-height: 1.4375;
  letter-spacing: 0.1em;
}
.mw_wp_form_preview .contactForm__flex {
  justify-content: unset;
}
.mw_wp_form_preview .contactForm__input {
  font-size: 1.6rem;
  letter-spacing: 0.1em;
}
.mw_wp_form_preview .contactForm__text {
  font-size: 1.6rem;
  font-weight: normal;
}
.mw_wp_form_preview .contactForm__text span {
  display: none;
}
.mw_wp_form_preview .contactForm__text .preview {
  display: none;
}

//pc-s
@media screen and (max-width: 1360px) {
  .contact .contactForm {
    margin-top: 40px;
    padding: 35.2px 0;
  }
  .contact .contactForm__inner {
    max-width: 640px;
  }
  .contact .contactForm__flex {
    max-width: 544px;
    margin-top: 32px;
  }
  .contact .contactForm__text {
    width: 168px !important;
    font-size: 1.36rem;
  }
  .contact .contactForm__text span {
    font-size: 1.04rem;
  }
  .contact .contactForm__check {
    max-width: 374.4px;
    font-size: 1.36rem;
  }
  .contact .contactForm__input {
    max-width: 374.4px;
  }
  .contact .contactForm__input input,
  .contact .contactForm__input textarea {
    max-width: 374.4px;
    font-size: 1.36rem;
    height: 28.8px;
    padding: 4.8px;
  }
  .contact .contactForm__input textarea {
    height: 241.6px;
  }
  .contact .contactForm__button {
    width: 240px;
  }
  .contact .contactForm__button::before {
    width: 13.6px;
    height: 4.8px;
    right: 13.6px;
  }
  .contact .contactButton {
    width: 240px;
    margin-top: 48px;
    padding: 16px 0;
    font-size: 1.52rem;
  }
  .contact .contactButton::before {
    width: 13.6px;
    height: 4.8px;
    right: 13.6px;
  }
  .contact .contactButton.-wh {
    margin-top: 12px;
  }
  .contact .contactButton.-wh::before {
    left: 13.6px;
  }
  .mw_wp_form .vertical-item + .vertical-item {
    margin-top: 8px !important;
  }
  .mw_wp_form_preview .contactForm__check {
    font-size: 1.28rem;
  }
  .mw_wp_form_preview .contactForm__input {
    font-size: 1.28rem;
  }
  .mw_wp_form_preview .contactForm__text {
    font-size: 1.28rem;
  }
}

// sp
@media screen and (max-width: 670px) {
  input[type="text"] {
    height: 36px;
  }
  textarea {
    height: 177px;
  }

  .contact .contactForm {
    margin-top: 56px;
    padding: 28px 0 36px;
  }
  .contact .contactForm__inner {
    max-width: 440px;
    margin: 0 auto;
  }
  .contact .contactForm__flex {
    flex-direction: column;
    margin-top: 32px;
  }
  .contact .contactForm__text {
    width: 100% !important;
    font-size: 1.6rem;
    line-height: 1.5625;
  }
  .contact .contactForm__text span {
    font-size: 1.3rem;
    letter-spacing: 0.1em;
  }
  .contact .contactForm__check {
    margin-top: 16px;
    font-size: 1.6rem;
  }
  .contact .contactForm__input {
    max-width: 440px;
  }
  .contact .contactForm__input input,
  .contact .contactForm__input textarea {
    max-width: 440px;
    margin-top: 8px;
    font-size: 1.6rem;
    padding: 6px;
  }
  .contact .contactForm__input textarea {
    height: 177px;
  }
  .contact .contactForm__button {
    width: 300px;
  }
  .contact .contactForm__button::before {
    width: 17px;
    height: 6px;
  }
  .contact .contactButton {
    width: 300px;
    padding: 20px 0;
    font-size: 1.9rem;
  }
  .contact .contactButton::before {
    width: 17px;
    height: 6px;
    right: 17px;
  }
  .contact .contactButton.-wh {
    margin-top: 15px;
  }
  .contact .contactButton.-wh::before {
    left: 17px;
  }
  .mw_wp_form .vertical-item + .vertical-item {
    margin-top: 14px !important;
  }
  .mw_wp_form_preview .contactForm__check {
    font-size: 1.6rem;
  }
  .mw_wp_form_preview .contactForm__input {
    font-size: 1.6rem;
  }
  .mw_wp_form_preview .contactForm__text {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .mw_wp_form_preview .contactForm__text .preview {
    display: block;
  }
}
