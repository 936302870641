.thanks {
  margin-top: 296px;
  text-align: center;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    margin-top: 236.8px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 192px;
  }
}
.thanks__title {
  font-size: 3.2rem;
  font-weight: bold;
  letter-spacing: 0.2em;

  @media screen and (max-width: 1360px) {
    font-size: 2.56rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 2.2rem;
  }
}
.thanks__text {
  margin-top: 76px;
  font-size: 1.8rem;
  line-height: 1.888888889;
  letter-spacing: 0.1em;

  @media screen and (max-width: 1360px) {
    margin-top: 60.8px;
    font-size: 1.44rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 28px;
    font-size: 1.5rem;
    line-height: 1.866666667;
  }
}
.thanks__btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: 0 auto;
  margin-top: 60px;
  font-weight: bold;
  letter-spacing: 0.2em;

  @media screen and (max-width: 1360px) {
    width: 240px;
    margin-top: 48px;
  }

  @media screen and (max-width: 670px) {
    width: 300px;
    margin-top: 52px;
  }
}
.thanks__btn::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 6px;
  top: 50%;
  left: 17px;
  transform: translateY(-50%) scale(-1, 1);
  background-image: url(../images/icon/arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;

  @media screen and (max-width: 1360px) {
    width: 13.6px;
    height: 4.8px;
    right: 13.6px;
  }

  @media screen and (max-width: 670px) {
    width: 17px;
    height: 6px;
  }
}
.thanks__btn:hover {
  opacity: 0.8;
  transition: opacity 0.5s;
}
.thanks__btn a {
  display: block;
  width: 300px;
  padding: 20px 0;
  background-color: $color-wh;
  border: 1px solid $color-main;
  color: $color-main;
  border-radius: 5px;
  transition: opacity 0.5s;
  font-size: 1.9rem;

  @media screen and (max-width: 1360px) {
    width: 240px;
    padding: 16px 0;
    font-size: 1.52rem;
  }

  @media screen and (max-width: 670px) {
    width: 300px;
    padding: 20px 0;
    font-size: 1.9rem;
  }
}

//pc-s
// @media screen and (max-width: 1360px) {
//   .thanks {
//     margin-top: 236.8px;
//   }
//   .thanks__title {
//     font-size: 2.56rem;
//   }
//   .thanks__text {
//     margin-top: 60.8px;
//     font-size: 1.44rem;
//   }
//   .thanks__btn {
//     width: 240px;
//     margin-top: 48px;
//   }
//   .thanks__btn::before {
//     width: 13.6px;
//     height: 4.8px;
//     right: 13.6px;
//   }
//   .thanks__btn a {
//     width: 240px;
//     padding: 16px 0;
//     font-size: 1.52rem;
//   }
// }

//sp
// @media screen and (max-width: 670px) {
//   .thanks {
//     margin-top: 192px;
//   }
//   .thanks__title {
//     font-size: 2.2rem;
//   }
//   .thanks__text {
//     margin-top: 28px;
//     font-size: 1.5rem;
//     line-height: 1.866666667;
//   }
//   .thanks__btn {
//     width: 300px;
//     margin-top: 52px;
//   }
//   .thanks__btn::before {
//     width: 17px;
//     height: 6px;
//   }
//   .thanks__btn a {
//     width: 300px;
//     padding: 20px 0;
//     font-size: 1.9rem;
//   }
// }
