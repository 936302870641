.header__btn {
  display: none;

  @media screen and (max-width: 1360px) {
    width: 50px;
    height: 50px;
  }
}

.drawer-btn {
  position: relative;
  width: 72px;
  height: 72px;
  z-index: 101;
  background-color: $color-main;
  cursor: pointer;

  @media screen and (max-width: 670px) {
    width: 60px;
    height: 60px;
  }
}
.drawer-btn > span {
  position: absolute;
  left: 26%;
  display: inline-block;
  width: 36px;
  height: 2px;
  background-color: $color-wh;
  transform-origin: 50%;
  transition: 0.4s;

  @media screen and (max-width: 1360px) {
    left: 26%;
    width: 36px;
  }

  @media screen and (max-width: 670px) {
    left: 30%;
    width: 26px;
  }
}
.drawer-btn > span:nth-of-type(1) {
  transform: rotate(0) translateY(-12px);

  @media screen and (max-width: 1360px) {
    transform: rotate(0) translateY(-11px);
  }
}
.drawer-btn > span:nth-of-type(3) {
  transform: rotate(0) translateY(12px);

  @media screen and (max-width: 1360px) {
    transform: rotate(0) translateY(11px);
  }
}
.drawer-btn.active {
  background-color: $color-wh;
}
.drawer-btn.active > span {
  background-color: $color-bk;
}
.drawer-btn.active > span:nth-of-type(1) {
  transform: translateY(0) rotate(-45deg);
}
.drawer-btn.active > span:nth-of-type(2) {
  opacity: 0;
}
.drawer-btn.active > span:nth-of-type(3) {
  transform: translateY(0) rotate(45deg);
}

.drawer-modal {
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-wh;
  transition: max-height 0.3s;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
}
.drawer-modal.open {
  visibility: visible;
  opacity: 1;
}
.drawer-modal__inner {
  width: 100%;
  height: 100%;
  padding-top: 64px;

  @media screen and (max-width: 1360px) {
    padding-top: 92px;
  }
}
.drawer-modal__container {
  height: 500px;
}
.drawer-modal__list {
  width: 100%;
}
.drawer-modal__item {
  position: relative;
  border-bottom: 1px solid #999899;
  margin: 0 25px;
  font-size: 1.7rem;
  letter-spacing: 0.2em;
  color: $color-bk;
  font-weight: bold;
}
.drawer-modal__item.-tel {
  max-width: 310px;
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: $color-main;
  border-radius: 4px;
}
.drawer-modal__item.-tel a {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
  font-weight: bold;
  color: $color-wh;
}
.drawer-modal__item.-tel img {
  width: 42px;
  margin-right: 12px;
}
.drawer-modal__item.acd {
  position: relative;
  padding: 22px 20px;
}
.drawer-modal__item.acd::before {
  content: "";
  position: absolute;
  width: 26px;
  height: 23px;
  right: 22px;
  top: 18px;
  background-image: url(../images/icon/plus.svg);
  background-repeat: no-repeat;
  transition: 0.4s;
}
.drawer-modal__item.is-active.acd::before {
  transform: rotate(45deg);
  transition: 0.4s;
}
.drawer-modal__item > a {
  display: block;
  padding: 22px 20px;
}
.drawer-modal__item .sublist {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition-duration: 0.3s;
}
.drawer-modal__item .sublist.is-open {
  height: auto;
  max-height: 250px;
  opacity: 1;
}
.drawer-modal__item .sublist__item {
  margin-top: 18px;
}
.drawer-modal__item .sublist__item > a {
  position: relative;
  display: block;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  color: #7f8080;
}

// ps-s
// @media screen and (max-width: 1360px) {
//   .header__btn {
//     width: 50px;
//     height: 50px;
//   }
//   .drawer-btn {
//     width: 60px;
//     height: 60px;
//   }
//   .drawer-btn > span {
//     left: 30%;
//     width: 26px;
//   }
//   .drawer-btn > span:nth-of-type(1) {
//     transform: rotate(0) translateY(-11px);
//   }
//   .drawer-btn > span:nth-of-type(3) {
//     transform: rotate(0) translateY(11px);
//   }
//   .drawer-modal__inner {
//     padding-top: 92px;
//   }
// }

// tb {
// @media screen and (max-width: 1080px) {
// }
