.pagetop {
  position: fixed;
  right: 1.8%;
  bottom: 2.6%;
  z-index: 1;
  display: none;

  @media screen and (max-width: 1080px) {
    right: 20px;
  }

  @media screen and (max-width: 670px) {
    right: 15px;
  }
}
.pagetop > a {
  display: block;
  width: 75px;

  @media screen and (max-width: 1360px) {
    width: 60px;
  }

  @media screen and (max-width: 670px) {
    width: 50px;
  }
}
.pagetop > a > img {
  width: 75px;
  transition: transform 0.2s;

  @media screen and (max-width: 1360px) {
    width: 60px;
  }

  @media screen and (max-width: 670px) {
    width: 50px;
  }
}

.pagetop > a:hover > img {
  transform: translateY(-5px);

  @media screen and (max-width: 1080px) {
    transform: translateY(0);
  }
}
