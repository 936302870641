.lower {
  position: relative;
  padding-top: 56px;

  @include media(md) {
    padding-top: res(66px);
  }

  @include media(lg) {
    padding-top: 66px;
  }
}

.lowerMv {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &.--topics {
    background-image: url(../images/pages/lower/topics_01_sp.png);

    @include media(sm) {
      background-image: url(../images/pages/lower/topics_01_pc.png);
    }
  }

  &.--blog {
    background-image: url(../images/pages/lower/blog_01_sp.png);

    @include media(sm) {
      background-image: url(../images/pages/lower/blog_01_pc.png);
    }
  }

  &.--grass {
    background-image: url(../images/pages/lower/grass_01_sp.png);

    @include media(sm) {
      background-image: url(../images/pages/lower/grass_01_pc.png);
    }
  }

  &.--garden {
    background-image: url(../images/pages/lower/garden_01_sp.png);

    @include media(sm) {
      background-image: url(../images/pages/lower/garden_01_pc.png);
    }
  }

  &.--works {
    background-image: url(../images/pages/lower/works_01_sp.png);

    @include media(sm) {
      background-image: url(../images/pages/lower/works_01_pc.png);
    }
  }

  &.--const {
    background-image: url(../images/pages/lower/construction_01_sp.png);

    @include media(sm) {
      background-image: url(../images/pages/lower/construction_01_pc.png);
    }
  }

  &.--company {
    background-image: url(../images/pages/lower/company_01_sp.png);

    @include media(sm) {
      background-image: url(../images/pages/lower/company_01_pc.png);
    }
  }

  &.--recruit {
    background-image: url(../images/pages/lower/recruit_01_sp.png);

    @include media(sm) {
      background-image: url(../images/pages/lower/recruit_01_pc.png);
    }
  }

  &::before,
  &::after {
    content: "";
  }

  &::before {
    padding-top: percentage(114 / 360);

    @include media(sm) {
      padding-top: percentage(176 / 768);
    }

    @include media(md) {
      padding-top: percentage(330 / 1440);
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(#1f3d6c, 0.4);
  }

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @include media(md) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.lowerMv__container {
  position: relative;
  height: 100%;
}

.lowerMv__title {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  .en,
  .jp {
    color: $color-wh;
  }

  .jp {
    display: flex;
    align-items: center;
    font-size: 2.2rem;
    font-weight: 700;

    @include media(sm) {
      font-size: res(4.4rem);
    }

    @include media(lg) {
      font-size: 4.4rem;
    }

    & > .small {
      padding-top: 2px;
      padding-left: 6px;
      font-size: 1.4rem;

      @include media(sm) {
        padding-top: res(4px);
        padding-left: res(14px);
        font-size: res(2.8rem);
      }

      @include media(lg) {
        padding-top: 4px;
        padding-left: 14px;
        font-size: 2.8rem;
      }
    }
  }

  .en {
    margin-top: 8px;
    font-size: 1rem;
    font-family: $font-en;
    letter-spacing: 0.05em;

    @include media(sm) {
      margin-top: res(20px);
      font-size: res(1.4rem);
    }

    @include media(lg) {
      margin-top: 20px;
      font-size: 1.4rem;
    }
  }

  .note {
    position: relative;
    background-color: $color-wh;
    margin-bottom: 8px;
    padding: 4px 7px;
    font-size: 1rem;
    font-weight: 700;
    color: $color-main;
    text-align: center;

    @include media(sm) {
      margin-bottom: res(18px);
      padding: res(8px) res(15px);
      font-size: res(1.5rem);
    }

    @include media(lg) {
      margin-bottom: 18px;
      padding: 8px 15px;
      font-size: 1.5rem;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 1px;
      left: 11px;
      transform: translateY(100%);
      border-style: solid;
      border-width: 0 5px 5px 0;
      border-color: transparent $color-wh transparent transparent;

      @include media(sm) {
        left: res(28px);
        border-width: 0 res(10px) res(10px) 0;
      }

      @include media(lg) {
        left: 28px;
        border-width: 0 10px 10px 0;
      }
    }
  }
}

.lowerHead {
  position: relative;
  padding-top: percentage(150 / 360);

  @include media(sm) {
    padding-top: percentage(176 / 768);
  }

  @include media(md) {
    padding-top: percentage(280 / 1440);
  }

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @include media(md) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.lowerHead__container {
  position: relative;
  height: 100%;
}

.lowerHead__title {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  .en,
  .jp {
    color: $color-main;
  }

  .jp {
    display: flex;
    align-items: center;
    font-size: 2.8rem;
    font-weight: 700;

    @include media(sm) {
      font-size: res(4.4rem);
    }

    @include media(lg) {
      font-size: 4.4rem;
    }
  }

  .en {
    margin-top: 10px;
    font-size: 1.2rem;
    font-family: $font-en;
    letter-spacing: 0.05em;

    @include media(sm) {
      margin-top: res(20px);
      font-size: res(1.4rem);
    }

    @include media(lg) {
      margin-top: 20px;
      font-size: 1.4rem;
    }
  }
}
