.company__container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1360px) {
    max-width: 768px;
  }

  @media screen and (max-width: 1080px) {
    padding: 0 15px;
  }

  @media screen and (max-width: 670px) {}
}
.company__img {
  margin-top: 86px;

  @media screen and (max-width: 1360px) {
    margin-top: 68.8px;
  }

  @media screen and (max-width: 670px) {
    margin: 0 calc(50% - 50vw);
    margin-top: 58px;
  }

  &>img{
    max-width: 980px;
    width: 100%;

    @media screen and (max-width: 1360px) {
      max-width: 784px;
    }

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }
}

.company .companyTextbox {
  position: relative;
  max-width: 688px;
  width: 100%;
  margin: 0 auto;
  margin-top: -40px;
  padding: 60px 80px 54px;
  background-color: $color-wh;
  z-index: 1;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 1360px) {
    max-width: 550.4px;
    margin-top: -32px;
    padding: 48px 64px 43.2px;
  }

  @media screen and (max-width: 670px) {
    max-width: 330px;
    margin-top: -10px;
    padding: 30px 35px 24px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  }
}
.companyTextbox__title {
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0.3em;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    font-size: 1.92rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 2.2rem;
  }
}

.companyTextbox__subtitle {
  margin-top: 26px;
  font-size: 2rem;
  line-height: 2.133333333;
  letter-spacing: 0.1em;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    margin-top: res(26px);
    font-size: res(2rem);
  }

  @media screen and (max-width: 670px) {
    margin-top: 12px;
    font-size: 1.7rem;
    line-height: 1.928571429;
  }
}

.companyTextbox__text {
  margin-top: 5px;
  font-size: 1.5rem;
  line-height: 2.133333333;
  letter-spacing: 0.1em;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    margin-top: res(5px);
    font-size: 1.2rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 5px;
    font-size: 1.4rem;
    line-height: 1.928571429;
  }
}
.companyVision {
  margin: 0 calc(50% - 50vw);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 147px;
  background-color: #dee9e0;

  @media screen and (max-width: 1360px) {
    margin-top: 117.6px;
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;
    margin-top: 60px;
    padding: 39px 0 48px;
  }
}
.companyVision__text {
  font-size: 3rem;
  line-height: 1.633333333;
  letter-spacing: 0.2em;
  font-weight: bold;
  color: $color-main;
  padding-bottom: 6px;
  border-bottom: 2.5px dotted $color-main;

  @media screen and (max-width: 1360px) {
    font-size: 2.4rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 2.3rem;
    font-weight: bold;
    line-height: 1.608695652;
  }
}

.companyVision__img {
  width: 570px;
  margin-top: -90px;
  margin-bottom: -58px;
  margin-left: 82px;

  @media screen and (max-width: 1360px) {
    width: 456px;
    margin-top: -72px;
    margin-bottom: -46.4px;
    margin-left: 65.6px;
  }

  @media screen and (max-width: 1080px) {
    width: 340px;
    margin-left: 40px;
  }

  @media screen and (max-width: 670px) {
    width: 330px;
    margin: 0;
    margin-top: 18px;
    margin-left: 0 !important;
  }
}
.companyProfile__list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 144px;

  @media screen and (max-width: 1360px) {
    margin-top: 115.2px;
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;
    margin-top: 46px;
  }
}
.companyProfile__item {
  // width: 100%;
  padding: 20px 0 15px;

  @media screen and (max-width: 1360px) {
    padding: 16px 0 12px;
  }

  @media screen and (max-width: 670px) {
    width: 100%;
    padding: 0 0 29px;
  }

  // &:last-child {
  //   border-left: 1px solid #dedede;

  //   @media screen and (max-width: 670px) {
  //     border-left: none;
  //     border-top: 1px solid #dedede;
  //     padding: 34px 0 0;
  //   }

  //   & .card {
  //     margin-left: auto;
  //   }
  // }
}

.companyProfile__item .card {
  display: flex;
  // max-width: 404px;
  width: 100%;

  @media screen and (max-width: 1360px) {
    // max-width: 323.2px;
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;
    max-width: 330px;
    padding: 0 15px;
    margin: 0 auto;
  }
}

 .companyProfile__item .card__img {
  width: 210px;

  @media screen and (max-width: 1360px) {
    width: res(210px);
  }

  @media screen and (max-width: 670px) {
    width: 210px;
    margin: 0 auto;
  }
}

 .companyProfile__item .card__textbox {
  max-width: 400px;
  width: 100%;
  margin-left: 28px;

  @media screen and (max-width: 1360px) {
    max-width: res(400px);
    margin-left: res(28px);
  }

  @media screen and (max-width: 670px) {
    max-width: 330px;
    margin-left: 0;
  }
}

 .companyProfile__item .card__text {
  margin-top: 30px;
  font-size: 1.5rem;
  letter-spacing: track(100);
  line-height: line(24,15);

  @media screen and (max-width: 1360px) {
    margin-top: res(30px);
    font-size: res(1.5rem);
  }

  @media screen and (max-width: 670px) {
    margin-top: 11px;
    font-size: 1.5rem;
    line-height: 1.666666667;
    letter-spacing: 0.1em;
  }
}

 .companyProfile__item .card__title {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.461538462;
  letter-spacing: 0.1em;
  color: $color-main;

  @media screen and (max-width: 1360px) {
    font-size: 1.04rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 14px;
    margin-left: 4px;
    font-size: 1.3rem;
    font-weight: bold;
  }
}

.companyProfile__item .card__name {
  margin-top: 10px;
  font-size: 2.3rem;
  font-weight: bold;
  letter-spacing: 0.2em;
  color: $color-main;

  @media screen and (max-width: 1360px) {
    margin-top: 8px;
    font-size: 1.52rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 2rem;
    margin-top: 2px;
    margin-left: 6px;
  }

  &.-mt{
    @media screen and (max-width: 670px) {
      margin-top: 6px;
    }
  }
}

.companyProfile__item .card__subtitle {
  margin-top: 10px;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.461538462;
  letter-spacing: 0.1em;
  color: #7f8080;

  @media screen and (max-width: 1360px) {
    font-size: 1.04rem;
  }

  @media screen and (max-width: 670px) {
    margin-top: 8px;
    margin-left: 4px;
    font-size: 1.3rem;
    font-weight: bold;
  }
}

.companyAbout {
  margin-top: 90px;

  @media screen and (max-width: 1360px) {
    margin-top: 90px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 52px;
  }
}

.companyAbout__title {
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0.2em;
  text-align: center;
  color: $color-bk;

  @media screen and (max-width: 1360px) {
    font-size: 1.92rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 2.3rem;
  }
}

 .companyAbout__table {
  margin-top: 34px;

  @media screen and (max-width: 1360px) {
    margin-top: 27.2px;
  }

  @media screen and (max-width: 670px) {
    margin-top: 20px;
  }
}

 .companyAbout__flex {
  display: flex;
}

.companyAbout__name {
  max-width: 240px;
  width: 100%;
  padding-left: 30px;
  padding-top: 20px;
  font-size: 1.7rem;
  font-weight: bold;
  color: #7f8080;
  border-top: 1px solid #7f8080;

  @media screen and (max-width: 1360px) {
    max-width: 192px;
    padding-left: 24px;
    padding-top: 16px;
    font-size: 1.36rem;
  }

  @media screen and (max-width: 670px) {
    max-width: 75px;
    padding-left: 0;
    padding-top: 18px;
    font-size: 1.5rem;
  }

  &.-last {
    border-bottom: 1px solid #7f8080;
  }
}

.companyAbout__text {
  width: 100%;
  margin-left: 18px;
  padding: 15px 28px;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  line-height: 1.6;
  color: $color-bk;
  border-top: 1px solid #dedede;

  @media screen and (max-width: 1360px) {
    margin-left: 14.4px;
    padding: 12px 22.4px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 670px) {
    margin-left: 10px;
    padding: 12px 0;
    font-size: 1.5rem;
    line-height: 1.666666667;
  }

  &.-last {
    border-bottom: 1px solid #dedede;
  }
}

.companyAbout__note {
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  line-height: 1.6;
  color: $color-bk;
  text-indent: -1em;
  padding-left: 1em;

  @media screen and (max-width: 1360px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 1.5rem;
    line-height: 1.666666667;
    letter-spacing: 0.1em;
  }
}

// @media screen and (max-width: 1360px) {
//   .company__container {
//     max-width: 768px;
//   }
//   .company__img {
//     margin-top: 68.8px;
//   }
//   .company__img img {
//     max-width: 784px;
//   }
//   .company .companyTextbox {
//     max-width: 550.4px;
//     margin-top: -32px;
//     padding: 48px 64px 43.2px;
//   }
//   .company .companyTextbox__title {
//     font-size: 1.92rem;
//   }
//   .company .companyTextbox__text {
//     margin-top: 20.8px;
//     font-size: 1.2rem;
//   }
//   .company .companyVision {
//     margin-top: 117.6px;
//   }
//   .company .companyVision__text {
//     font-size: 2.4rem;
//   }
//   .company .companyVision__img {
//     width: 456px;
//     margin-top: -72px;
//     margin-bottom: -46.4px;
//     margin-left: 65.6px;
//   }
//   .company .companyProfile__list {
//     margin-top: 115.2px;
//   }
//   .company .companyProfile__item {
//     padding: 16px 0 12px;
//   }
//   .company .companyProfile__item .card {
//     max-width: 323.2px;
//   }
//   .company .companyProfile__item .card__img {
//     width: 128px;
//   }
//   .company .companyProfile__item .card__textbox {
//     max-width: 171.2px;
//     margin-left: 22.4px;
//   }
//   .company .companyProfile__item .card__text {
//     margin-top: 11.2px;
//     font-size: 1.12rem;
//   }
//   .company .companyProfile__item .card__title {
//     font-size: 1.04rem;
//   }
//   .company .companyProfile__item .card__name {
//     margin-top: 8px;
//     font-size: 1.52rem;
//   }
//   .company .companyAbout {
//     margin-top: 90px;
//   }
//   .company .companyAbout__title {
//     font-size: 1.92rem;
//   }
//   .company .companyAbout__table {
//     margin-top: 27.2px;
//   }
//   .company .companyAbout__name {
//     max-width: 192px;
//     padding-left: 24px;
//     padding-top: 16px;
//     font-size: 1.36rem;
//   }
//   .company .companyAbout__text {
//     margin-left: 14.4px;
//     padding: 12px 22.4px;
//     font-size: 1.2rem;
//   }
//   .company .companyAbout__note {
//     font-size: 1.2rem;
//   }
// }

//tb
// @media screen and (max-width: 1080px) {
//   .company__container {
//     padding: 0 15px;
//   }
//   .company .companyVision__img {
//     width: 340px;
//     margin-left: 40px;
//   }
// }

//sp
// @media screen and (max-width: 670px) {
//   .company__img {
//     margin: 0 calc(50% - 50vw);
//     margin-top: 58px;
//   }
//   .company__img img {
//     width: 100%;
//   }
//   .company .companyTextbox {
//     max-width: 330px;
//     margin-top: -10px;
//     padding: 30px 35px 24px;
//     box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
//   }
//   .company .companyTextbox__title {
//     font-size: 2.2rem;
//   }
//   .company .companyTextbox__text {
//     margin-top: 12px;
//     font-size: 1.4rem;
//     line-height: 1.928571429;
//   }
//   .company .companyVision {
//     flex-direction: column;
//     margin-top: 60px;
//     padding: 39px 0 48px;
//   }
//   .company .companyVision__text {
//     font-size: 2.3rem;
//     font-weight: bold;
//     line-height: 1.608695652;
//   }
//   .company .companyVision__img {
//     width: 330px;
//     margin: 0;
//     margin-top: 18px;
//     margin-left: 0 !important;
//   }
//   .company .companyProfile__list {
//     flex-direction: column;
//     margin-top: 46px;
//   }
//   .company .companyProfile__item {
//     width: 100%;
//     padding: 0 0 29px;
//   }
//   .company .companyProfile__item:last-child {
//     border-left: none;
//     border-top: 1px solid #dedede;
//     padding: 34px 0 0;
//   }
//   .company .companyProfile__item .card {
//     flex-direction: column;
//     max-width: 330px;
//     padding: 0 15px;
//     margin: 0 auto;
//   }
//   .company .companyProfile__item .card__img {
//     width: 160px;
//     margin: 0 auto;
//   }
//   .company .companyProfile__item .card__textbox {
//     max-width: 330px;
//     margin-left: 0;
//   }
//   .company .companyProfile__item .card__text {
//     margin-top: 11px;
//     font-size: 1.5rem;
//     line-height: 1.666666667;
//     letter-spacing: 0.1em;
//   }
//   .company .companyProfile__item .card__title {
//     margin-top: 14px;
//     margin-left: 4px;
//     font-size: 1.3rem;
//     font-weight: bold;
//   }
//   .company .companyProfile__item .card__name {
//     font-size: 2rem;
//     margin-top: 2px;
//     margin-left: 6px;
//   }
//   .company .companyProfile__item .card__name.-mt {
//     margin-top: 6px;
//   }
//   .company .companyAbout {
//     margin-top: 52px;
//   }
//   .company .companyAbout__title {
//     font-size: 2.3rem;
//   }
//   .company .companyAbout__table {
//     margin-top: 20px;
//   }
//   .company .companyAbout__name {
//     max-width: 75px;
//     padding-left: 0;
//     padding-top: 18px;
//     font-size: 1.5rem;
//   }
//   .company .companyAbout__text {
//     margin-left: 10px;
//     padding: 12px 0;
//     font-size: 1.5rem;
//     line-height: 1.666666667;
//   }
//   .company .companyAbout__note {
//     font-size: 1.5rem;
//     line-height: 1.666666667;
//     letter-spacing: 0.1em;
//   }
// }
