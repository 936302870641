/* footer
------------------------------------------------ */
.footer {
  margin-top: 120px;
  background-color: $color-main;

  @media screen and (max-width: 1360px) {
    margin-top: 96px;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 78px;
  }
}

.footer.-top {
  margin-top: 140px;

  @media screen and (max-width: 1360px) {
    margin-top: 112px;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 80px;
  }
}

.footer__logo {
  position: relative;
  transition: opacity 0.3s;

  &>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &>img {
    width: 168px;

    @media screen and (max-width: 1360px) {
      width: res(168px);
    }

    @media screen and (max-width: 1080px) {
      width: 177px;
    }
  }

  &:hover {
    opacity: 0.7;
    transition: opacity 0.3s;
  }
}

.footer__container {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 62px 0;

  @media screen and (max-width: 1360px) {
    max-width: 960px;
    padding: res(62px) 0;
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    padding: 60px 0 34px;
  }
}

.footer__flexitem {
  margin-left: 72px;

  @media screen and (max-width: 1360px) {
    margin-left: res(72px);
  }

  @media screen and (max-width: 1080px) {
    margin-left: 0;
  }
}

.footer__list {
  display: flex;

  @media screen and (max-width: 1080px) {
    margin-top: 42px;
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;
    margin-top: 0;
  }
}
.footer__item {
  margin-left: 54px;
  font-size: 1.6rem;
  color: $color-wh;
  letter-spacing: 0.1em;
  transition: opacity 0.3s;

  @media screen and (max-width: 1360px) {
    margin-left: 43.2px;
    font-size: 1.28rem;
  }

  @media screen and (max-width: 670px) {
    font-size: 1.6rem;
    margin-left: 0;
    margin-top: 22px;
  }

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    opacity: 0.8;
    transition: opacity 0.3s;
  }
}

.footerAdd {
  display: flex;
  align-items: flex-end;
  margin-top: 36px;
  color: $color-wh;
  letter-spacing: 0.075em;

  @media screen and (max-width: 1360px) {
    margin-top: 28.8px;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 38px;
    flex-direction: column;
    align-items: normal;
  }
}

.footerAdd__title {
  font-size: 1.6rem;

  @media screen and (max-width: 1360px) {
    font-size: 1.28rem;
  }

  @media screen and (max-width: 1080px) {
    font-size: 1.6rem;
  }
}

.footerAdd__text {
  margin-top: 10px;
  font-size: 1.3rem;
  line-height: 1.615384615;

  @media screen and (max-width: 1360px) {
    margin-top: 8px;
    font-size: 1.04rem;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 10px;
    font-size: 1.3rem;
  }
}

.footerAdd__img {
  position: relative;
  margin-left: 70px;
  transition: opacity 0.3s;

  @media screen and (max-width: 1360px) {
    margin-left: res(70px);
  }

  @media screen and (max-width: 1080px) {
    margin-left: auto;
    margin-top: 10px;
  }

  &>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &>img {
    width: 30px;

    @media screen and (max-width: 1360px) {
      width: 24px;
    }

    @media screen and (max-width: 1080px) {
      width: 30px;
    }
  }

  &:hover {
    opacity: 0.7;
    transition: opacity 0.3s;
  }
}

.footer__copy {
  position: absolute;
  margin-top: auto;
  margin-left: 26px;
  color: $color-wh;
  bottom: 62px;
  right: 0;

  @media screen and (max-width: 1360px) {
    margin-left: 20.8px;
    bottom: 49.6px;
  }

  @media screen and (max-width: 1080px) {
    position: relative;
    max-width: 240px;
    width: 100%;
    margin-top: 25px;
    margin-left: 0;
    right: auto;
    bottom: auto;
  }
}

.footerTEL {
  background-color: #f1f0e9;

  &.-none {
    display: none;
  }
}

.footerTEL__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  padding: 35px 0 33px;

  @media screen and (max-width: 1360px) {
    max-width: 752px;
    padding: 28px 0 26.4px;
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    padding: 44px 0 50px;
  }
}

.footerTELTbox {
  position: relative;

  & a {
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
      transition: opacity 0.2s;
    }
  }
}

.footerTELTbox__text {
  margin-left: 80px;
  font-size: 1.7rem;
  letter-spacing: 0.1em;
  color: $color-main;

  @media screen and (max-width: 1360px) {
    margin-left: res(80px);
    font-size: 1.36rem;
  }

  @media screen and (max-width: 1080px) {

    font-size: 1.5rem;
  }
}

.footerTELTbox__flex {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
  transition: opacity 0.2s;

  @media screen and (max-width: 1360px) {
    margin-top: 11.2px;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 10px;
  }

  & a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s;
  }
}

.footerTELTbox__phone {
  margin-top: 18px;
  color: $color-wh;

  @media screen and (max-width: 1360px) {
    margin-top: 14.4px;
  }

  @media screen and (max-width: 1080px) {
    margin-top: 6px;
  }

  & a {
    position: relative;
    padding-left: 18px;
    font-size: 1.8rem;
    font-family: $font-en;
    letter-spacing: 0.1em;

    @media screen and (max-width: 1360px) {
      padding-left: 14.4px;
      font-size: 1.44rem;
    }

    @media screen and (max-width: 1080px) {
      display: flex;
      align-items: center;
      max-width: 200px;
      width: 100%;
      margin: 0 auto;
      padding-left: 18px;
      font-size: 2rem;
      line-height: 1.5;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 10px;
      height: 18px;
      transform: translateY(-50%);
      background-image: url(../images/icon/phone.svg);
      background-repeat: no-repeat;
      background-size: contain;

      @media screen and (max-width: 1360px) {
        width: 8px;
        height: 14.4px;
      }

      @media screen and (max-width: 1080px) {
        width: 10px;
        height: 18px;
      }
    }

    &:last-child{
      &::before {
        content: none;

        @media screen and (max-width: 1080px) {
          content: "";
        }
      }
    }

    & span {
      font-family: $font;
      padding-left: 6px;
      font-size: 1.4rem;
      letter-spacing: 0.075em;
      color: $color-wh;

      @media screen and (max-width: 1360px) {
        padding-left: 4.8px;
        font-size: 1.12rem;
      }

      @media screen and (max-width: 1080px) {
        display: block;
        margin-top: 3px;
        padding-left: 6px;
        font-size: 1.4rem;
      }
    }
  }
}

.footerTELTbox__img {
  &>img{
    width: 36px;

    @media screen and (max-width: 1360px) {
      width: 28.8px;
    }

    @media screen and (max-width: 1080px) {
      width: 30px;
    }
  }
}
.footerTELTbox__tel {
  margin-left: 16px;
  font-size: 5.5rem;
  font-family: $font-en;
  letter-spacing: 0.1em;
  color: $color-main;

  @media screen and (max-width: 1360px) {
    margin-left: 12.8px;
    font-size: 4.4rem;
  }

  @media screen and (max-width: 1080px) {
    margin-left: 12px;
    margin-bottom: 5px;
    font-size: 4.5rem;
  }
}

.footerTELMbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 460px;
  width: 100%;
  padding: 36px 0;
  background-color: $color-main;
  border-radius: 5px;
  transition: opacity 0.2s;

  @media screen and (max-width: 1360px) {
    max-width: 368px;
    padding: 28.8px 0;
  }

  @media screen and (max-width: 1080px) {
    max-width: 310px;
    margin-top: 20px;
    padding: 20px 0;
  }

  & a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s;
  }
}

.footerTELMbox__img {
  & img{
    width: 52px;

    @media screen and (max-width: 1360px) {
      width: 52px;
    }

    @media screen and (max-width: 1080px) {
      width: 40px;
    }
  }
}
.footerTELMbox__text {
  margin-left: 28px;
  font-size: 2.4rem;
  letter-spacing: 0.1em;
  color: $color-wh;

  @media screen and (max-width: 1360px) {
    margin-left: 22.4px;
    font-size: 1.92rem;
  }

  @media screen and (max-width: 1080px) {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

// pc-s
// @media screen and (max-width: 1360px) {
//   .footer {
//     margin-top: 96px;
//   }
//   .footer.-top {
//     margin-top: 112px;
//   }
//   .footer__logo img {
//     width: 160px;
//   }
//   .footer__container {
//     max-width: 960px;
//     padding: 49.6px 0;
//   }
//   .footer__flexitem {
//     margin-left: 49.6px;
//   }
//   .footer__item {
//     margin-left: 43.2px;
//     font-size: 1.28rem;
//   }
//   .footer .footerAdd {
//     margin-top: 28.8px;
//   }
//   .footer .footerAdd__title {
//     font-size: 1.28rem;
//   }
//   .footer .footerAdd__text {
//     margin-top: 8px;
//     font-size: 1.04rem;
//   }
//   .footer .footerAdd__img {
//     margin-left: 12.8px;
//   }
//   .footer .footerAdd__img img {
//     width: 24px;
//   }
//   .footer__copy {
//     margin-left: 20.8px;
//     bottom: 49.6px;
//   }
//   .footerTEL__container {
//     max-width: 752px;
//     padding: 28px 0 26.4px;
//   }
//   .footerTEL .footerTELTbox__text {
//     margin-left: 46.4px;
//     font-size: 1.36rem;
//   }
//   .footerTEL .footerTELTbox__flex {
//     margin-top: 11.2px;
//   }
//   .footerTEL .footerTELTbox__phone {
//     margin-top: 14.4px;
//   }
//   .footerTEL .footerTELTbox__phone a {
//     padding-left: 14.4px;
//     font-size: 1.44rem;
//   }
//   .footerTEL .footerTELTbox__phone a span {
//     padding-left: 4.8px;
//     font-size: 1.12rem;
//   }
//   .footerTEL .footerTELTbox__phone a::before {
//     width: 8px;
//     height: 14.4px;
//   }
//   .footerTEL .footerTELTbox__img img {
//     width: 28.8px;
//   }
//   .footerTEL .footerTELTbox__tel {
//     margin-left: 12.8px;
//     font-size: 4.4rem;
//   }
//   .footerTEL .footerTELMbox {
//     max-width: 368px;
//     padding: 28.8px 0;
//   }
//   .footerTEL .footerTELMbox__img img {
//     width: 52px;
//   }
//   .footerTEL .footerTELMbox__text {
//     margin-left: 22.4px;
//     font-size: 1.92rem;
//   }
// }

// tb
// @media screen and (max-width: 1080px) {
//   .footer {
//     margin-top: 78px;
//   }
//   .footer.-top {
//     margin-top: 80px;
//   }
//   .footer__logo img {
//     width: 156px;
//   }
//   .footer__container {
//     flex-direction: column;
//     padding: 30px 0 34px;
//   }
//   .footer__flexitem {
//     margin-left: 0;
//   }
//   .footer__list {
//     margin-top: 22px;
//   }
//   .footer .footerAdd {
//     margin-top: 38px;
//   }
//   .footer .footerAdd__title {
//     font-size: 1.6rem;
//   }
//   .footer .footerAdd__text {
//     margin-top: 10px;
//     font-size: 1.3rem;
//   }
//   .footer .footerAdd__img {
//     margin-left: 8px;
//   }
//   .footer .footerAdd__img img {
//     width: 30px;
//   }
//   .footer__copy {
//     position: relative;
//     max-width: 240px;
//     width: 100%;
//     margin-top: 34px;
//     margin-left: 0;
//     right: auto;
//     bottom: auto;
//   }
//   .footerTEL__container {
//     flex-direction: column;
//     padding: 44px 0 50px;
//   }
//   .footerTEL .footerTELTbox__text {
//     margin-left: 34px;
//     font-size: 1.5rem;
//   }
//   .footerTEL .footerTELTbox__flex {
//     margin-top: 10px;
//   }
//   .footerTEL .footerTELTbox__phone {
//     margin-top: 6px;
//   }
//   .footerTEL .footerTELTbox__phone a {
//     display: flex;
//     align-items: center;
//     max-width: 200px;
//     width: 100%;
//     margin: 0 auto;
//     padding-left: 18px;
//     font-size: 2rem;
//     line-height: 1.5;
//   }
//   .footerTEL .footerTELTbox__phone a span {
//     display: block;
//     margin-top: 3px;
//     padding-left: 6px;
//     font-size: 1.4rem;
//   }
//   .footerTEL .footerTELTbox__phone a::before {
//     width: 10px;
//     height: 18px;
//   }
//   .footerTEL .footerTELTbox__phone a:last-child::before {
//     content: "";
//   }
//   .footerTEL .footerTELTbox__img img {
//     width: 30px;
//   }
//   .footerTEL .footerTELTbox__tel {
//     margin-left: 12px;
//     margin-bottom: 5px;
//     font-size: 4.5rem;
//   }
//   .footerTEL .footerTELMbox {
//     max-width: 310px;
//     margin-top: 20px;
//     padding: 20px 0;
//   }
//   .footerTEL .footerTELMbox__img img {
//     width: 40px;
//   }
//   .footerTEL .footerTELMbox__text {
//     font-size: 1.8rem;
//     font-weight: bold;
//   }
// }

// sp
// @media screen and (max-width: 670px) {
//   .footer__list {
//     flex-direction: column;
//     margin-top: 0;
//   }
//   .footer__item {
//     font-size: 1.6rem;
//     margin-left: 0;
//     margin-top: 22px;
//   }
// }
